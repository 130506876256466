<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Create Page</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-650px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                  >
                    <span v-if="n == 1">Settings</span>
                    <span v-if="n == 2">Edit Header</span>
                    <span v-if="n == 3">Edit body</span>
                    <span v-if="n == 4">Edit Iamge</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-autocomplete
                        v-model="store_id"
                        :items="stores"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Select Store*"
                        @change="ChangeLanguage"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-model="page"
                        :items="pages"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Select Page*"
                      ></v-autocomplete>
                    </div>
                    <div class="stepper-footer">
                      <div></div>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                    v-if="formData"
                  >
                    <div class="stepper-content">
                      <div class="d-flex justify-content-end">
                        <div class="w-25">
                          <v-autocomplete
                            v-if="languages"
                            v-model="header_language"
                            :items="languages"
                            outlined
                            item-name="text"
                            item-value="index"
                            dense
                            clearable
                            label="Language"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <div
                        v-for="(item, index) in languages"
                        :key="'header' + index"
                      >
                        <ckeditor
                          v-model="formData['header_' + item.index]"
                          :config="editorConfig"
                          v-if="header_language == item.index"
                        ></ckeditor>
                      </div>
                    </div>
                    <div class="stepper-footer mt-5">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(2)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(2)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="3" :key="`3-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-contnent">
                      <div class="d-flex justify-content-end">
                        <div class="w-25">
                          <v-autocomplete
                            v-if="languages"
                            v-model="body_language"
                            :items="languages"
                            outlined
                            item-name="text"
                            item-value="index"
                            dense
                            clearable
                            label="Language"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <div
                        v-for="(item, index) in languages"
                        :key="'body' + index"
                      >
                        <ckeditor
                          v-model="formData['body_' + item.index]"
                          :config="editorConfig"
                          v-if="body_language == item.index"
                        ></ckeditor>
                      </div>
                    </div>

                    <div class="stepper-footer mt-5">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(3)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="4" :key="`4-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-file-input
                        v-if="formData"
                        v-model="formData.image"
                        show-size
                        counter
                        multiple
                        dense
                        outlined
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Image"
                        clearable
                      ></v-file-input>
                    </div>

                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="submitCreateForm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>

          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { UPDATE_CREATE_DATA } from "@/core/services/store/custom.module";

export default {
  name: "AddPages",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    tabIndex: 0,
    // editor: ClassicEditor,
    editorConfig: {},
    header_language: null,
    body_language: null,
    languages: null,
    store_id: null,
    page: null,
    formData: null,
    GFlag: null,
    e1: 1,
    steps: 4,
  }),
  beforeMount() {
    this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {});
  },
  computed: {
    pages: function () {
      if (this.$store.getters.getCUSTOMCreateData.pages) {
        return this.$store.getters.getCUSTOMCreateData.pages;
      }
      return undefined;
    },
    stores: function () {
      if (this.$store.getters.getCUSTOMCreateData) {
        return this.$store.getters.getCUSTOMCreateData.stores;
      }
      return undefined;
    },
  },
  mounted() {
    this.resetData();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    closeModal() {
      this.resetData();
      this.dialog = false;
    },
    converFormData() {
      let data = new FormData();
      data.append("store_id", this.store_id);
      data.append("page", this.page);
      for (var key in this.formData) {
        if (this.formData[key]) {
          if (key == "image") data.append(key, this.formData[key][0]);
          else data.append(key, this.formData[key]);
        }
      }
      return data;
    },
    submitCreateForm() {
      const data = this.converFormData();
      this.pageLoader(true);
      ApiService.post("/stores/portal/pages/store", data)
        .then(() => {
          this.pageLoader(false);
          Swal.fire({
            title: "Created",
            text: `New Custom Page has been Created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.$forceUpdate();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData = null;
      this.store_id = null;
      this.page = null;
      this.languages = null;
      this.body_language = null;
      this.header_language = null;
      this.nextStep(4);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n == 1) {
          if (!this.store_id || !this.page)
            this.errorMessage("Should select store and page!");
          else this.e1 = n + 1;
        } else if (n == 2) {
          var test = true;
          this.languages.map((item) => {
            if (
              !this.formData["header_" + item.index] ||
              this.formData["header_" + item.index] == ""
            ) {
              test = false;
              return;
            }
          });
          if (test) this.e1 = n + 1;
          else
            this.errorMessage("Should Complete Header data in all languages!");
        } else if (n == 3) {
          var testBody = true;
          this.languages.map((item) => {
            if (
              !this.formData["body_" + item.index] ||
              this.formData["body_" + item.index] == ""
            ) {
              testBody = false;
              return;
            }
          });
          if (testBody) this.e1 = n + 1;
          else this.errorMessage("Should Complete Body data in all languages!");
        } else this.e1 = n + 1;
      }
    },
    previewStep(n) {
      if (n == 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    ChangeLanguage() {
      var selectedItem = this.stores.filter(
        (item) => item.index == this.store_id
      );
      this.languages = selectedItem[0].languages;
      if (this.languages) {
        this.header_language = this.languages[0].index;
        this.body_language = this.languages[0].index;
      }
      this.createFormData();
    },
    createFormData() {
      this.formData = {
        image: null,
      };
      this.languages.map((item) => {
        this.formData["header_" + item.index] = "";
        this.formData["body_" + item.index] = "";
      });
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
